import React, { useState, useContext, useEffect } from 'react'
import {
  // Navigate,
  Route,
  Routes
} from 'react-router-dom'
import is from 'is_js'
import MainLayout from './MainLayout'

import Profile from 'pages/profile'

import ErrorLayout from './ErrorLayout'
import { toast, ToastContainer } from 'react-toastify'
import { CloseButton } from 'components/common/Toast'
import appRoutes from 'routes/routes';

import Error404 from 'components/errors/Error404'
import Error500 from 'components/errors/Error500'

import Dashboard from 'pages/dashboard'
import Notifications from 'pages/notifications'
import AppContext from 'context/Context'
import { useAccountContext } from 'context/Account'
import UnconfiguredAppLayout from './UnconfiguredAppLayout'
import UnconfiguredPage from 'pages/unconfigured'
import Notification from 'pages/notifications/notification'

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList
  useContext(AppContext)
  const { appState } = useAccountContext()

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows')
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome')
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox')
    }
  }, [HTMLClassList])

  /**
   * Create App Routes
   * @param routes
   * @returns {*}
   */
  function createAppRoutes (routes) {
    // re-write that routes.map as non-arrow function.
    return routes.map(function (route) {
      if (route.to && !route.hidden) {
        return <Route key={route.id} path={route.to} element={route.component} />
      } else if (route.to && route.hidden) {
        return <Route key={route.id} path={route.to} element={route.component} labelDisable />
      } else if (route.children) {
        return createAppRoutes(route.children)
      } else {
        return null;
      }
    })
  }

  const aAppRoutes = createAppRoutes(appRoutes);

  return (
    <>
      <Routes>

        <Route key='errorlayout' element={<ErrorLayout />}>
          <Route key='errors404' path="errors/404" element={<Error404 />} />
          <Route eky='errors500' path="errors/500" element={<Error500 />} />
        </Route>

        <Route key='mainlayout' element={<MainLayout />}>
          <Route key='dashboard' path="/" element={<Dashboard />} />
          <Route key='notifications' path="notifications" element={<Notifications />} />
          <Route key='user-profile' path="user/profile" element={<Profile />} />
          <Route key='notifications-noteid' path="notifications/:noteId" element={<Notification />} />
          {aAppRoutes}
        </Route>

      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={true}
        position={toast.POSITION.TOP_LEFT}
        // autoClose=false
        theme='colored'
      />
    </>
  )
}

export default Layout
