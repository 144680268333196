import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap';

const AppMenu = () => {
  const cardComponents = [];

  // Keep track of imported module paths to avoid duplicates
  const importedPaths = new Set();
  let aComponents = [];

  function importAll (r) {
    r.keys().forEach(key => {
      const pathComponents = key.split('/');
      if (pathComponents[0] === 'modules' && pathComponents[pathComponents.length - 1] === 'Card.jsx') {
        const moduleName = pathComponents[1];
        const component = r(key).default;
        console.log('Importing Card:', moduleName);
        cardComponents.push({ moduleName, component });
      }
    });
  }

  importAll(require.context('modules', true, /\.jsx$/));

  aComponents = cardComponents.map((cardComponent, index) => {
    const Component = cardComponent.component;
    return (
      <Component key={index}/>
    );
  });

  return (
    <>
      <div className='row'>
        <div className='col'>
          <h1>Your Apps</h1>
        </div>
      </div>

      <Row>
        {aComponents}
      </Row>
    </>
  );
}

export default AppMenu
