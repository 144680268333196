import React from 'react'
import Rewards from 'modules/rewards/pages/index'

const RewardsRoutes = {
  label: 'Rewards',
  id: 'rewards',
  children: [
    {
      name: 'Main',
      id: 'rewards-index',
      icon: 'chart-line',
      active: true,
      to: 'modules/rewards/pages/index',
      component: <Rewards />
    },
    {
      name: 'Reports',
      id: 'rewards-reports',
      icon: 'chart-line',
      active: true,
      children: [
        {
          name: 'Transactions',
          id: 'rewards-report-transactions',
          active: true,
          to: 'modules/rewards/pages/reports/transactions',
          component: 'modules/rewards/pages/reports/transactions'
        },
        {
          name: 'Sent Reports',
          id: 'rewards-report-sent-reports',
          active: true,
          to: 'modules/rewards/pages/reports/sent-reports',
          component: 'modules/rewards/pages/reports/sent-reports'
        }
      ]
    }
  ]
}

export default RewardsRoutes
