import React from 'react'
import DFSMain from 'modules/dfs/pages/index'

const DFSRoutes = {
  label: 'DFS',
  id: 'dfs',
  children: [
    {
      name: 'Main',
      id: 'dfs-index',
      icon: 'chart-line',
      active: true,
      to: 'modules/dfs/pages/index',
      component: <DFSMain />
    },
    {
      name: 'Reports',
      id: 'dfs-reports',
      icon: 'chart-line',
      active: true,
      children: [
        {
          name: 'Redemptions',
          id: 'dfs-report-redemptions',
          active: true,
          to: 'modules/dfs/pages/reports/redemptions',
          component: 'modules/dfs/pages/reports/redemptions'
        },
        {
          name: 'Reservations',
          id: 'dfs-report-reservations',
          active: true,
          to: 'modules/dfs/pages/reports/reservations',
          component: 'modules/dfs/pages/reports/reservations'
        }
      ]
    }
  ]
}

export default DFSRoutes
