import { Button, Card } from 'react-bootstrap';
import RewardsLogo from 'assets/img/logos/b&w/8.png';
import React from 'react';

const RewardsCard = () => {
  const aData = {
    label: 'Rewards',
    description: 'Rewards',
    id: 'rewards',
    icon: 'truck',
    to: 'modules/rewards/pages/index',
    active: true,
    logo: RewardsLogo
  };

  return (
    <div className='col-lg-4 col-md-4 col-sm-1'>
      <Card id={aData.id}>
        <Card.Img style={{ width: '100%' }} src={aData.logo} variant='top'/>
        <Card.Body>
          <Card.Title as='h5'>
            {aData.label}
          </Card.Title>
          <Card.Text>
            {aData.description}
          </Card.Text>
          <Button href={aData.to} color="primary" size="sm">
            Launch App
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RewardsCard;
