import React, { useState } from 'react'
import PageHeader from 'components/common/PageHeader'
import { Button, Card } from 'react-bootstrap';

const DriverDashboard = () => {
  return (
    <>
      <PageHeader
        title='Driver Dashboard'
        description="Driver Dashboard Control Panel"
        className='mb-3'
      />
      <div className='row'>
        <div className='col-12'>

          <Card>
            <Card.Body>
              <Card.Title as='h5'>
                User Manager
              </Card.Title>
              <Card.Text>
                Manage Driver Dashboard users.
                <Button href="user-manager" color="primary" size="sm">
                  Go
                </Button>
              </Card.Text>
            </Card.Body>
          </Card>

        </div>
      </div>
    </>
  )
}

export default DriverDashboard
