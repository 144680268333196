import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const EditUser = (props) => {
  const oUser = props.user;
  const cancelCallback = props.cancel;
  const saveCallback = props.save;

  const defaultUser = {
    user_id: oUser.user_id || '',
    name: oUser.name || '',
    email: oUser.email || '',
    phone_number: oUser.phone_number || '',
    status: oUser.status || ''
  }

  const [isProcessing, setIsProcessing] = useState(false)
  const [formData, setFormData] = useState(defaultUser)

  useEffect(() => {
    setFormData({
      ...formData,
      user_id: oUser.user_id,
      name: oUser.name,
      email: oUser.email,
      phone_number: oUser.phone_number,
      status: oUser.status
    })
  }, [oUser])

  const handleFieldChange = e => {
    console.log('handleFieldChange: ', e.target.name, e.target.value);

    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const handleCancel = () => {
    console.log('CANCELLING');
    cancelCallback();
  }

  const editUserRequest = async (oPayload) => {
    const oResponse = await fetch('https://...', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(oPayload)
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsProcessing(true)

    try {
      const oPayload = {
        ...formData,
        user_id: parseInt(oUser.user_id),
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone_number
      };

      console.log('Submitting form data: ', oPayload, ' for user: ', oUser.user_id);
      const oResponse = await editUserRequest(oPayload);
      setIsProcessing(false)

      if (oResponse.status !== 200) {
        toast.error('Error: ' + oResponse.status, {
          autoClose: false
        });
        return false;
      }

      if (!oResponse || !oResponse.data) {
        toast.error('Successful but unknown response received from server', {
          autoClose: false
        });
        return false;
      }

      if (oResponse.data.error) {
        toast.error(oResponse.error.message, {
          autoClose: false
        });
        return false;
      }

      toast.success('Edit user request successfully posted');
      saveCallback()
    } catch (error) {
      setIsProcessing(false)
      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  return (
    <>
      <Modal
        centered
        show={true}
        onHide={handleCancel}
        size={'xl'}
      >
        <>
          <Modal.Header className='modal-header-success'>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Col} lg={12} controlId='editDevice'>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>User ID</Form.Label>
                    <Form.Control
                      name='user_id'
                      type='text'
                      value={oUser.user_id}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name='name'
                      type='text'
                      value={formData.name}
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name='email'
                      type='text'
                      value={formData.email}
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      name='phone_number'
                      type='text'
                      value={formData.phone_number}
                      onChange={handleFieldChange}
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      type='select'
                      value={formData.status}
                      onChange={handleFieldChange}
                      name='status'
                      placeholder='Select Status'
                    >
                      <option value=''>Select Status</option>
                      <option value='Active'>Active</option>
                      <option value='Unconirmed'>Unconfirmed</option>
                      <option value='Disabled'>Disabled</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Row className='mb-3 g-3'>
                <Col className='text-end'>
                  <Button
                    variant='secondary'
                    type='button'
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col lg='2'>
                  <Button
                    variant='primary'
                    type='submit'
                  >
                    {isProcessing
                      ? (
                        <FontAwesomeIcon
                          icon='circle-notch'
                          className='me-1 fa-spinner fa-spin'
                        />
                        )
                      : ''
                    }
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </>
      </Modal>
    </>
  )
}

EditUser.propTypes = {
  user: PropTypes.object,
  save: PropTypes.func,
  cancel: PropTypes.func,
}

EditUser.defaultProps = {
  user: {},
  save: () => { return true; },
  cancel: () => { return true; }
}

export default EditUser
