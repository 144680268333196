import DFSRoutes from 'modules/dfs/routes'
import RewardsRoutes from 'modules/rewards/routes'
import DriverDashboardRoutes from 'modules/driver-dashboard/routes'

export const YourAppsRoutes = {
  label: 'Your Apps Main',
  id: 'your-apps-main',
  labelDisable: true,
  children: [
    {
      name: 'Your Apps',
      id: 'your-apps',
      icon: 'chart-pie',
      to: '/',
      active: true
    }
  ]
}

export const HelpRoutes = {
  label: 'Help',
  id: 'help',
  labelDisable: true,
  children: [
    {
      name: 'Help',
      id: 'help_help',
      active: true,
      icon: 'question-circle',
      children: []
    }
  ]
};

export default [
  YourAppsRoutes,
  DFSRoutes,
  RewardsRoutes,
  DriverDashboardRoutes,
];
