import { React, useState, useEffect } from 'react'
import PageHeader from 'components/common/PageHeader'
import { Button, Card, Form, InputGroup, Row, Col } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter'
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox'
import EditUser from 'modules/driver-dashboard/components/EditUser'

const DriverDashboardUserManager = () => {
  const [userPool, setUserPool] = useState(null);
  const [users, setUsers] = useState(null);
  const [usersTable, setUsersTable] = useState(null);
  const [nEditing, setEditing] = useState(null);
  const [aStoredData, setStoredData] = useState([]);

  const oPageHeader = (
    <PageHeader title='Driver Dashboard User Manager' description="Driver Dashboard User Manager" className='mb-3' />
  );

  const oUserPoolForm = (
    <Row>
      <Col>
        <InputGroup className="mb-3">
          <InputGroup.Text>User Pool:</InputGroup.Text>
          <Form.Select size="lg" name='pool-picker' id='pool-picker'>
            <option>Choose a User Pool...</option>
            <option value='1'>Roady's Drivers</option>
          </Form.Select>
          <Button variant="outline-primary" onClick={switchUserPool}>Load</Button>
        </InputGroup>
      </Col>
    </Row>
  );

  /**
   * On load trigger
   */
  useEffect(() => {
    console.log('DriverDashboardUserManager useEffect was hit!');
    // Set default user pool
  }, []);

  /**
   * Whenever userPool changes.
   */
  useEffect(() => {
    if (userPool !== null) {
      console.log('userPool set to: ', userPool);
      loadUsers(userPool);
    }
  }, [userPool]);

  /**
   * Whenever users changes.
   */
  useEffect(() => {
    console.log('users set to: ', users);
    renderUsers(users);
  }, [users]);

  /**
   * Returns the actions format for the users table.
   * @param oUser
   */
  function getActionsFormat (oUser) {
    console.log('getActionsFormat was hit with user: ', oUser);
    const nUserId = oUser.user_id;

    return (
      <div>
        <Button data-user={JSON.stringify(oUser)} variant='outline-primary' size='sm' className='me-2' onClick={editUser}>
          Edit
        </Button>
        <a className='btn btn-outline-primary' href={`user-details/${nUserId}`}>
          View Details
        </a>
      </div>
    );
  }

  /**
   * Views a user's details.
   * @param oEvent
   * @constructor
   */
  function ViewUserDetails (oEvent) {
    const oUser = JSON.parse(oEvent.target.getAttribute('data-user'));
    console.log('ViewUserDetails was hit with oUser: ', oUser);
  }

  /**
   * Edits a user.
   * @param oEvent
   */
  function editUser (oEvent) {
    const oUser = JSON.parse(oEvent.target.getAttribute('data-user'));
    console.log('editUser was hit with oUser: ', oUser);
    setEditing(oUser.user_id);
  }

  /**
   * Renders the markup for the users table.
   */
  function renderUsers (aUsers) {
    console.log('renderUsers was hit with aUsers: ', aUsers);
    let oUserTable = (
      <div className='row'>
        <div className='col'>
          <Card>
            <Card.Body>
              <Card.Title as='h5'>
                Users
              </Card.Title>
              <Card.Text>
                No users found.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    );

    if (aUsers !== null) {
      const columns = [
        {
          accessor: 'name',
          Header: 'Name',
          headerProps: { className: 'text-900' }
        },
        {
          accessor: 'email',
          Header: 'Email',
          headerProps: { className: 'text-900' }
        },
        {
          accessor: 'phone_number',
          Header: 'Phone number',
          headerProps: { className: 'text-900' }
        },
        {
          accessor: 'status',
          Header: 'Status',
          headerProps: { className: 'text-900' }
        },
        {
          accessor: 'actions',
          Header: 'Actions',
          headerProps: { className: 'text-900' },
        }
      ];

      // Create a table with the user data.
      oUserTable = (
        <AdvanceTableWrapper
          columns={columns}
          data={aUsers}
          sortable
          pagination
          perPage={5}
        >
          <Row className="flex-end-center mb-3">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table/>
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs-10 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={aUsers.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      );
    }

    setUsersTable(oUserTable);
  }

  /**
   * Loads users from a user pool.
   */
  function loadUsers (pool) {
    console.log('loadUsers was hit with pool: ', pool);
    let aUsers = null;

    if (pool === '1') {
      aUsers =
        [
          {
            user_id: 's4-fgadfjdfg-34g3g3rg-34f',
            name: 'John Doe',
            status: 'Active',
            email: 'johndoe@roadyscorp.com',
            phone_number: '555-555-5555'
          },
          {
            user_id: 's4-fgadfmjg-34g3g3rg-61s',
            name: 'Jane Doe',
            status: 'Active',
            email: 'janedoe@roadyscorp.com',
            phone_number: '555-555-5556'
          },
          {
            user_id: 's4-fgadifmjg-34ghhsg-18b',
            name: 'Jim Doe',
            status: 'Active',
            email: 'jimdoe@roadyscorp.com',
            phone_number: '555-555-5557'
          },
          {
            user_id: 's4-fgm92ndjb3-3nqkd8g-50g',
            name: 'Jerry Doe',
            status: 'Active',
            email: 'jerrydoe@roadyscorp.com',
            phone_number: '555-555-5558'
          }
        ];

      // Add the actions to the users.
      aUsers = aUsers.map((oUser) => {
        oUser.actions = getActionsFormat(oUser);
        return oUser;
      });
    } else {
      console.log('No users found for pool: ', pool);
    }

    // Set stored data, which is used for editing.
    const aStoredData = [];
    if (aUsers !== null) {
      aUsers.forEach((oUser) => {
        aStoredData[oUser.user_id] = oUser;
      });
    }
    setStoredData(aStoredData);

    // Set the users.
    setUsers(aUsers);
  }

  function switchUserPool () {
    console.log('switchUserPool was hit!');
    const poolPicker = document.getElementById('pool-picker');
    const poolPickerValue = poolPicker.value;
    console.log('poolPickerValue: ', poolPickerValue);
    setUserPool(poolPickerValue);
  }

  const saveClickHandler = (oUser) => {
    console.log('saveClickHandler was hit with oUser: ', oUser);
  }

  const cancelClickHandler = () => {
    console.log('cancelClickHandler was hit!');
    setEditing(null);
  }

  let oEditModal = null;
  if (nEditing !== null) {
    oEditModal = (
      <EditUser
        save={saveClickHandler}
        cancel={cancelClickHandler}
        user={aStoredData[nEditing]}
      />
    );
  }

  return (
    <>
      {oEditModal}
      {oPageHeader}
      {oUserPoolForm}
      {usersTable}
    </>
  )
}

export default DriverDashboardUserManager
