import { React, useState, useEffect } from 'react'
import PageHeader from 'components/common/PageHeader'
import { Button, Card, Form, InputGroup, Row, Col, Container } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import EditUser from 'modules/driver-dashboard/components/EditUser';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Divider from 'components/common/Divider';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

const DriverDashboardUserDetails = (props) => {
  // Read the user id from the URL.
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [oPageHeader, setPageHeader] = useState(null);
  const [oUserTable, setUserTable] = useState(
    <Row>
      <Col>
        <p>User data is not yet available.</p>
      </Col>
    </Row>
  );
  const [oUserTransactionTable, setUserTransactionTable] = useState(
    <Row>
      <Col>
        <p>Transaction data is not yet available.</p>
      </Col>
    </Row>
  );

  /**
   * On load trigger, sets the page header.
   */
  useEffect(() => {
    setPageHeader(
      <PageHeader title={'Driver Dashboard User Details'} description="Viewing details for user."
                  className='mb-3'/>
    );
  }, []);

  /**
   * On userId change, loads the user.
   */
  useEffect(() => {
    if (userId) {
      console.log('DriverDashboardUserDetails useEffect was hit with: ', userId);
      loadUser(userId);
    }
  }, [userId]);

  /**
   * Whenever user changes, call render user.
   */
  useEffect(() => {
    if (user) {
      console.log('user set to: ', user)
      const nUserId = user.user_id;
      setPageHeader(
        <PageHeader title={'Driver Dashboard User Details for: ' + nUserId} description='Viewing details for user'
                    className='mb-3'/>
      );
      renderUser(user);
    }
  }, [user]);

  const renderUser = (oUser) => {
    console.log('renderUser was hit with user: ', oUser);
    const oUserTable = (
      <div id={'user-table'}>
        <Card>
          <Card.Body>
            <Form>
              <Row>
                <Col>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Driver Dashboard Account Status</Form.Label>
                          <Form.Control type="text" value={oUser.status} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>

        <Divider />

        <Card>
          <Card.Body>
            <Form>
              <Row>
                <Col>
                  <Container>

                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>User ID</Form.Label>
                          <Form.Control type="text" value={oUser.user_id} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control type="text" value={oUser.name} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="text" value={oUser.email} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>DFS First Transaction</Form.Label>
                          <Form.Control type="text" value={oUser.dfs_first_trx} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>DFS Last Transaction</Form.Label>
                          <Form.Control type="text" value={oUser.dfs_last_trx} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Card Number</Form.Label>
                          <Form.Control type="text" value={oUser.rewards_card_number} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Balance</Form.Label>
                          <Form.Control type="text" value={oUser.balance} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Showers</Form.Label>
                          <Form.Control type="text" value={oUser.shower_balance} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Rewards Card Issuer</Form.Label>
                          <Form.Control type="text" value={oUser.rewards_card_issuer} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Card Activation</Form.Label>
                          <Form.Control type="text" value={oUser.card_activation} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Card Last Trx</Form.Label>
                          <Form.Control type="text" value={oUser.card_last_trx} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Card Status</Form.Label>
                          <Form.Control type="text" value={oUser.card_status} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>

                  </Container>
                </Col>

              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
    setUserTable(oUserTable);
    renderUserTransactions(oUser);
  };

  /**
   * Renders the user transactions in a tabbed interface.  I think perhaps it would make snes to only
   * initially display the appropriate information per program for the users, For example for DFS we'll
   * show the information we get with the user pull, ie: first and last transaction dates.  For rewards,
   * we'll show the rewards card number, balance, showers, issuer, activation, last transaction, and status.
   * This way the top level details can be seen at a glance, and the user can click on the tab to see more
   * detailed information.
   *
   * Ideally also we can have a "Load DFS Transactions" and a "Load Rewards Transactions" button that will
   * pull and populate that data only when requested, for performance reasons.
   * @param oUser
   */
  const renderUserTransactions = (oUser) => {
    const aTabs = ['DFS', 'Rewards'];
    const aFinalTabs = [];
    aTabs.forEach((sTab) => {
      const oTab = (
        <Tab key={sTab + '-tab-key'} eventKey={sTab} title={sTab} className='border p-3'>
          <p>
            Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro
            synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher
            retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip
            placeat salvia cillum iphone.
          </p>
        </Tab>
      );
      aFinalTabs.push(oTab);
    });

    const oTransactionsTabs = (
      <Tabs variant='pills' defaultActiveKey="profile" id="uncontrolled-tab-example" className='mb-3'>
        {aFinalTabs}
      </Tabs>
    );

    setUserTransactionTable(oTransactionsTabs);
  }
  /**
   * Loads users from a user pool.
   */
  const loadUser = (nUserId) => {
    console.log('loadUser was hit with user id: ', nUserId);
    setUser({
      user_id: 's4-fgadfjdfg-34g3g3rg-34f',
      name: 'John Doe',
      status: 'Active',
      email: 'johndoe@roadyscorp.com',
      rewards_card_number: '1234-5678-9012-3456',
      balance: 1000,
      shower_balance: 2
    });
  }

  return (
    <>
      {oPageHeader}
      {oUserTable}
      <Divider />
      {oUserTransactionTable}
    </>
  )
}

EditUser.propTypes = {
  user: PropTypes.object
}

EditUser.defaultProps = {
  user: {}
}

export default DriverDashboardUserDetails
