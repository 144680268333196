import React from 'react'
import DriverDashboard from 'modules/driver-dashboard/pages/index'
import UserManager from 'modules/driver-dashboard/pages/user-manager'
import UserDetails from 'modules/driver-dashboard/pages/user-details'

const DriverDashboardRoutes = {
  label: 'Driver Dashboard',
  id: 'driver-dashboard',
  children: [
    {
      name: 'Main',
      id: 'driver-dashboard-index',
      icon: 'chart-line',
      active: true,
      to: 'modules/driver-dashboard/pages/index',
      component: <DriverDashboard />
    },
    {
      name: 'User Manager',
      id: 'driver-dashboard-user-manager',
      icon: 'chart-line',
      active: true,
      to: 'modules/driver-dashboard/pages/user-manager',
      component: <UserManager />
    },
    {
      name: 'User Details',
      id: 'driver-dashboard-user-details',
      icon: 'chart-line',
      active: true,
      hidden: true,
      labelDisable: true,
      to: 'modules/driver-dashboard/pages/user-details/:userId', // Relative path
      component: <UserDetails />
    }
  ],
}

export default DriverDashboardRoutes
